import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Badge from "react-bootstrap/Badge";
import $ from "jquery";
class Experience extends Component {


  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      sharedData: {},
      resumeData: {},
    };
  }


  componentDidMount() {
    this.loadSharedData();
    this.loadResumeFromPath();
    console.log('componentDidMount called');
  }

  loadResumeFromPath() {
    var path = 'res_primaryLanguage.json'
    $.ajax({
      url: path,
      dataType: "json",
      cache: true,
      success: function (data) {
        this.setState({ resumeData: data });
        console.log('loadResumeFromPath called');
      }.bind(this),
      error: function (xhr, status, err) {
        alert(err);
      },
    });
  }

  loadSharedData() {
    $.ajax({
      url: `portfolio_shared_data.json`,
      dataType: "json",
      cache: true,
      success: function (data) {
        this.setState({ sharedData: data });
        console.log('loadSharedData called');
        document.title = `${this.state.sharedData.basic_info.name}`;
        var title = this.state.sharedData.basic_info.name;
        console.log('title ' + title);
      }.bind(this),
      error: function (xhr, status, err) {
        alert(err);
      },

    });
  }

  render() {
    // if (this.state.resumeExperience && this.state.resumeBasicInfo) {
      var sectionName = this.state.resumeData?.basic_info?.section_name?.experience;
      var work = this.state.resumeData?.experience?.map(function (work, i) {
        const technologies = work.technologies;
        const mainTechnologies = work.mainTech;

        var mainTech = mainTechnologies.map((technology, i) => {
          return (
            <Badge pill className="main-badge mr-2 mb-2" key={i}>
              {technology}
            </Badge>
          );
        });
        var tech = technologies.map((technology, i) => {
          return (
            <Badge pill className="experience-badge mr-2 mb-2" key={i}>
              {technology}
            </Badge>
          );
        });
        return (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date={work.years}
            iconStyle={{
              background: "#AE944F",
              color: "#fff",
              textAlign: "center",
            }}
            icon={<i className="fab fa-angular experience-icon"></i>}
            key={i}
          >
            <div style={{ textAlign: "left", marginBottom: "4px" }}>
              {mainTech}
            </div>

            <h3
              className="vertical-timeline-element-title"
              style={{ textAlign: "left" }}
            >
              {work.title}
            </h3>
            <h4
              className="vertical-timeline-element-subtitle"
              style={{ textAlign: "left" }}
            >
              {work.company}
            </h4>
            <div style={{ textAlign: "left", marginTop: "15px" }}>{tech}</div>
          </VerticalTimelineElement>
        );
      });
    // }

    return (
      <section id="resume" className="pb-5">
        <div className="col-md-12 mx-auto">
          <div className="col-md-12">
            <h1 className="section-title" style={{ color: "black" }}>
              <span className="text-black" style={{ textAlign: "center" }}>
                {sectionName}
              </span>
            </h1>
          </div>
        </div>
        <div className="col-md-8 mx-auto">
          <VerticalTimeline>
            {work}
            <VerticalTimelineElement
              iconStyle={{
                background: "#AE944F",
                color: "#fff",
                textAlign: "center",
              }}
              icon={
                <i className="fas fa-hourglass-start mx-auto experience-icon"></i>
              }
            />
          </VerticalTimeline>
        </div>
      </section>
    );
  }
}

export default Experience;
