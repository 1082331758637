import React, { Component } from "react";
import ProjectDetailsModal from "./ProjectDetailsModal";
import $ from "jquery";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      sharedData: {},
      resumeData: {},
    };
  }


  componentDidMount() {
    this.loadSharedData();
    this.loadResumeFromPath();
    console.log('componentDidMount called');
  }

  loadResumeFromPath() {
    var path = 'res_primaryLanguage.json'
    $.ajax({
      url: path,
      dataType: "json",
      cache: true,
      success: function (data) {
        this.setState({ resumeData: data });
        console.log('loadResumeFromPath called');
      }.bind(this),
      error: function (xhr, status, err) {
        alert(err);
      },
    });
  }

  loadSharedData() {
    $.ajax({
      url: `portfolio_shared_data.json`,
      dataType: "json",
      cache: true,
      success: function (data) {
        this.setState({ sharedData: data });
        console.log('loadSharedData called');
        document.title = `${this.state.sharedData.basic_info.name}`;
        var title = this.state.sharedData.basic_info.name;
        console.log('title ' + title);
      }.bind(this),
      error: function (xhr, status, err) {
        alert(err);
      },

    });

  }

  render() {
    let detailsModalShow = (data) => {
      this.setState({ detailsModalShow: true, deps: data });
    };

    let detailsModalClose = () => this.setState({ detailsModalShow: false });
    // if (this.state.resumeProjects && this.props.resumeBasicInfo) {
      
      var ideas = this.state.resumeData?.basic_info?.section_name?.ideas
      // var sectionName = this.state.resumeData.projects;
      var projects = this.state.resumeData?.projects?.map(function (projects) {
        return (
          <div
            className="col-sm-12 col-md-6 col-lg-4"
            key={projects.title}
            style={{ cursor: "pointer" }}
          >
            <span className="portfolio-item d-block">
              {/* <div className="foto" > */}
              <div className="foto" onClick={() => detailsModalShow(projects)}>
                <div>
                  <img
                    src={projects.images[0]}
                    alt="projectImages"
                    height="230"
                    style={{marginBottom: 0, paddingBottom: 0, position: 'relative'}}
                  />
                  <span className="project-date">{projects.startDate}</span>
                  <br />
                  <p className="project-title-settings mt-3">
                    {projects.title}
                  </p>
                </div>
              </div>
            </span>
          </div>
        );
      });
    // }

    return (
      <section id="portfolio">
        <div className="col-md-12">
          <h1 className="section-title" style={{ color: "black" }}>
            <span>{ideas}</span>
          </h1>
          <div className="col-md-12 mx-auto">
            <div className="row mx-auto">{projects}</div>
          </div>
          <ProjectDetailsModal
            show={this.state.detailsModalShow}
            onHide={detailsModalClose}
            data={this.state.deps}
          />
        </div>
      </section>
    );
  }
}

export default Projects;
