import React from "react";
// import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
// import { useParams } from "react-router-dom";


const WishFestivals = () => {
  
    const [name, setName] = React.useState("");

    var path = name ? "/wishpage/"+name : "/wishpage/skill india"
    const changeHandler = (e) => {
        const { value } = e.target;
        if (value !== "") {
            setName(value);
        }
    };
    return (
        <div >
            <h1> Wish you Happy Janamastmi frommm  </h1>

            <div className="App">
                <input
                    type="text"
                    enterKeyHint="Your Name Please "
                    onChange={changeHandler}
                />
            </div>
            {/* <input ref={(a) => this._inputElement = a} placeholder="Your Name Please" onChange={()=>firstInput()} >
            </input> */}

            <NavLink style={{ margin: 5, color: '#333344' }} exact activeClassName="active_class" to = {path}>
            {/* <NavLink style={{ margin: 5, color: '#333344' }} exact activeClassName="active_class" to = '/wishpage/kartick'> */}
                News
            </NavLink>

        </div>
    )

}
export default WishFestivals