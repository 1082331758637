import React, { Component } from "react";
import { Icon } from "@iconify/react";
import githubIcon from "@iconify/icons-logos/github-icon";
import youtubeIcon from "@iconify/icons-logos/youtube-icon";
import facebookIcon from "@iconify/icons-logos/facebook";
import instagramIcon from "@iconify/icons-logos/instagram-icon";
import Footer from "./Footer";
import $ from "jquery";
// import HeaderButtonBar from "./HeaderButtonBar";
import CookieConsentBanner from './CookieConsent';
// import { Navbar } from "react-bootstrap";
import HeaderNavBar from './Navbar/HeaderNavBar'

class AboutUs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      sharedData: {},
      resumeData: {},
    };
  }


  componentDidMount() {
    this.loadSharedData();
    this.loadResumeFromPath();
    console.log('componentDidMount called');
  }

  loadResumeFromPath() {
    var path = 'res_primaryLanguage.json'
    $.ajax({
      url: path,
      dataType: "json",
      cache: true,
      success: function (data) {
        this.setState({ resumeData: data });
        console.log('loadResumeFromPath called');
      }.bind(this),
      error: function (xhr, status, err) {
        alert(err);
      },
    });
  }

  loadSharedData() {
    $.ajax({
      url: `portfolio_shared_data.json`,
      dataType: "json",
      cache: true,
      success: function (data) {
        this.setState({ sharedData: data });
        console.log('loadSharedData called');
        document.title = `${this.state.sharedData.basic_info.name}`;
        var title = this.state.sharedData.basic_info.name;
        console.log('title ' + title);
      }.bind(this),
      error: function (xhr, status, err) {
        alert(err);
      },

    });

  }

  render() {

    var profilepic = "images/" + this.state.sharedData?.basic_info?.image;
    var sectionName = this.state.resumeData?.basic_info?.section_name?.about;
    var hello = this.state.resumeData?.basic_info?.description_header;
    var about = this.state.resumeData?.basic_info?.description;
    var address = this.state.resumeData?.basic_info?.address;
    var email = this.state.resumeData?.basic_info?.email;
    var phone = this.state.resumeData?.basic_info?.phone;




    var data = this.state.resumeData?.basic_info?.info?.map(function (data, i) {
      return (
        <div>
          {data?.header ? (
            <div>

              <p
                className="text-left"
                style={{ fontSize: "100%", marginTop: "4px" }}
              >
                <b> {data.header}</b>
              </p>

            </div>
          ) : null}

          <div>

            {data?.description ? (
              <div>

                <p
                  className="text-left"
                  style={{ fontSize: "100%", marginTop: "4px", textTransform: 'capitalize' }}
                >
                  {data.description}
                </p>

              </div>
            ) : null}
          </div>

        </div>


      );
    });




    return (
      <section id="AboutUs">
        {/* <HeaderButtonBar></HeaderButtonBar> */}
        <HeaderNavBar></HeaderNavBar>

        <div className="col-md-14">
          <h1 className="col-md-14 mx-auto text-center" style={{ margin: "40px", color: "black" }}>
            <span>{sectionName}</span>
          </h1>
          <div className="row center mx-auto mb-5">
            <div className="col-md-4 mb-5 center">
              <div className="polaroid">
                <span style={{ cursor: "auto" }}>

                  <img style={{ marginLeft: "8px" }}
                    height="250px"
                    src={profilepic}
                    alt="Avatar placeholder"
                  />
                  <div className='row-center'>
                    <a href={"https://www.facebook.com/chaman.panchal/"} target="_blank" rel="noopener noreferrer">
                      <Icon
                        icon={facebookIcon}
                        style={{ fontSize: "350%", margin: "9% 2% 0 5%" }}
                      />
                    </a>
                    <a href={"https://github.com/chamankumar44"} target="_blank" rel="noopener noreferrer">
                      <Icon
                        icon={githubIcon}
                        style={{ fontSize: "300%", margin: "9% 2% 0 5%" }}
                      />
                    </a>
                    <a href={"https://www.youtube.com/@frolic_chinu8561"} target="_blank" rel="noopener noreferrer">
                      <Icon
                        icon={youtubeIcon}
                        style={{ fontSize: "300%", margin: "9% 2% 0 5%" }}
                      />
                    </a>
                    <a href={"https://www.instagram.com/frolic_chinu/"} target="_blank" rel="noopener noreferrer">
                      <Icon
                        icon={instagramIcon}
                        style={{ fontSize: "300%", margin: "9% 2% 0 5%" }}
                      />
                    </a>
                  </div>
                  <div style={{ marginTop: "20px" }}><b>Founder : Mr. Chaman Panchal (M.Tech Engineer) </b></div>
                </span>

              </div>
            </div>

            <div className="col-md-8 center">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <span
                      className="iconify"
                      data-icon="emojione:red-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:yellow-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:green-circle"
                      data-inline="false"
                    ></span>
                  </div>
                  <div
                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                    style={{
                      height: "auto",
                      fontSize: "132%",
                      lineHeight: "200%",
                    }}
                  >
                    <br />
                    <span className="wave">{hello} :) </span>
                    <br />
                    <br />
                    <span>{about}</span>
                    <span>{data}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row center mx-auto mb-5">


            <div className="col-md-8 center">
              <div className="col-md-10">
                <div className="card">

                  <div
                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                    style={{
                      height: "auto",
                      fontSize: "132%",
                      lineHeight: "200%",
                    }}
                  >
                    <br />
                    <div className="text-center" style={{ fontSize: "20px", margin: "10px" }}><b>Contact Us </b> </div>
                    <span ><b>Address :</b> {address} </span>
                    <br />

                    <span><b>Phone : </b> {phone}</span>
                    <br />
                    <span><b>Email :</b> {email}</span>
                    <pre className="default">

                    </pre>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <div>
          <CookieConsentBanner />
        </div>

      </section>
    );
  }
}

export default AboutUs;
