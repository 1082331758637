import React, { Component } from "react";
import $ from "jquery";
import Footer from "./Footer";
import HeaderButtonBar from "./HeaderButtonBar";
class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            policyData: {},
        };
    }

    componentDidMount() {
        this.loadResumeFromPath();

    }

    loadResumeFromPath() {
        var path = 'privacy/privacypolicy.json'
        // var path = 'privacypolicy.json'
        $.ajax({
            url: path,
            dataType: "json",
            cache: true,
            success: function (data) {
                this.setState({ policyData: data });
                console.log('loadResumeFromPath called');
            }.bind(this),
            error: function (xhr, status, err) {
                alert(err);
            },
        });
    }


    render() {

      
        var section_name = this.state.policyData?.privacy?.policy?.section_name
        var address = this.state.policyData?.privacy?.policy?.address
        var website = this.state.policyData?.privacy?.policy?.website
        var description1 = this.state.policyData?.privacy?.policy?.description1
        var description2 = this.state.policyData?.privacy?.policy?.description2
        var personaldata = this.state.policyData?.privacy?.policy?.personaldata
        var usagedata = this.state.policyData?.privacy?.policy?.usagedata
        var cookies = this.state.policyData?.privacy?.policy?.cookies
        var dataprocessors = this.state.policyData?.privacy?.policy?.dataprocessors
        var email = this.state.policyData?.privacy?.policy?.email
        var phone = this.state.policyData?.privacy?.policy?.phone

        var data = this.state.policyData?.privacy?.policy?.data.map(function (data, i) {
            return (
                <li key={i}>
                    <span>
                        <div >
                            <i className={data.class} style={{ fontSize: "220%",  color: "black" }}>
                                <p 
                                    className="text-left"
                                    style={{ fontSize: "40%", marginTop: "4px" }}
                                >
                                    <b> { i+1 }. {data.header}</b>
                                </p>
                                <p
                                    className="text-left"
                                    style={{ fontSize: "30%", marginTop: "4px" ,textTransform: 'capitalize'}}
                                >
                                   {data.description}
                                </p>
                            </i>
                        </div>
                    </span>
                </li>
            );
        });


        return (
         
                <div style={{ color: "black",background : "#FCF9EA" }}>
                     <HeaderButtonBar></HeaderButtonBar>
                    <div style={{ color: "black", marginLeft : "20px",marginRight : "20px"  }}>
                        <h1 className="section-title"  style={{ color: "black" }}>
                            <h1 style={{ textTransform: 'capitalize' ,  margin : "30px"}} ><b>{section_name}</b></h1>
            
                            <h3 className="text-left" style={{ textTransform: 'capitalize',marginBottom : "10px" }}   > {description1}</h3>
                            <h3 className="text-left" style={{ textTransform: 'capitalize' ,marginBottom : "10px"}}  > <b>Operation : </b> {description2}</h3>
                            <h3 className="text-left"  style={{ textTransform: 'capitalize' ,marginBottom : "10px"}}  ><b>Personal Data : </b> {personaldata}</h3>
                            <h3 className="text-left" style={{ textTransform: 'capitalize' ,marginBottom : "10px"}}  ><b>Usage Data : </b> {usagedata}</h3>

                            <h3 className="text-left" style={{ textTransform: 'capitalize' ,marginBottom : "10px"}}  ><b>Data Processors : </b> {dataprocessors}</h3>
                            <span style={{ color: "black" }} className="text-white">{data}</span>
                        </h1>
                        <h3 className="text-left"  style={{ textTransform: 'capitalize'}}margin = "40" >  {cookies}</h3>
                        <h3 className="text-left"  margin = "40" ><b> Website : </b>{website}</h3>
                        <h3 className="text-left"  margin = "40" ><b> Email : </b>{email}</h3>
                        <h3 className="text-left"  margin = "40" ><b> Contact : </b>{phone}</h3>
                        <h3 className="text-left" style={{ textTransform: 'capitalize'}} margin = "40" > <b> Address : </b> {address}</h3>

                    </div>
                    
                    <Footer></Footer>
                </div>
          
        );
    }
}

export default PrivacyPolicy;
