import React, { Component } from "react";
import HeaderNavBar from './Navbar/HeaderNavBar'
import $ from "jquery";
import Footer from "./Footer";
import CookieConsentBanner from './CookieConsent';

import { NavLink } from "react-router-dom";
class Prepration extends Component {
    static jsonFilePath = "prepration/ios_interview_data.json";
    constructor(props) {
        super(props);
        this.state = {
            preprationData: {},

        };


    }

    componentDidMount() {
        this.loadPreprationData();
        console.log('componentDidMount called');
    }


    loadPreprationData() {
        $.ajax({
            url: `prepration/prepration_data.json`,

            dataType: "json",
            cache: true,
            success: function (data) {
                this.setState({ preprationData: data });
            }.bind(this),
            error: function (xhr, status, err) {
                // alert("data " + err);
            },

        });

    }


    render() {

        let onClickFunction = (item) => {
            Prepration.jsonFilePath = item.jsonpath
        };
        var item = this.state.preprationData?.intervew_topics?.icons?.map(function (item, i) {
        
            return (
                <div >
                    <NavLink style={{ margin: 5, color: '#333344' }} exact activeClassName="active_class" to="/interview_section"  >
                        <div onClick={() => { onClickFunction(item) }} className="card" width="20px" style={{ background: "#FCF9EA", height: "150px", width: "150px", fontSize: 15, textAlign: 'center', margin: "30px", color: '#229922', fontWeight: 'bold' }} >
                            <div style={{ margin: "20px", background: "#FCF9EA" }}>
                                <li className="list-inline-item mx-40" key={i}>
                                    <span>
                                  
                                        <div className="text-center skills-tile">
                                            <i className={item.class} style={{ fontSize: "220%" }}>
                                                <p
                                                    className="text-center"
                                                    style={{ fontSize: "30%", marginTop: "4px" }}
                                                >
                                                    {/* {item.name} */}
                                                </p>
                                            </i>
                                        </div>
                                        <span>{item.name}</span>
                                    </span>
                                </li>
                            </div>
                        </div>
                    </NavLink>
                </div>

            )
    
        });

        return (
            <section id="about">
                <HeaderNavBar></HeaderNavBar>

                <div className="text-center" style={{ fontSize: "30px", margin: "20px" }}>

                    <b>Interview Questions And Answers </b>
                </div>

                <div className="center mx-auto"  >
                    
                        <div className="center row mx-auto  grid_list"  >{item}</div>
                
                    {/* <div className="grid_list" style={{ marginLeft: "0px" }}>
                        {item}
                    </div> */}
                </div>

                <Footer />
                <div>
                    <CookieConsentBanner />
                </div>

            </section>

        )
    }

}


export default Prepration;