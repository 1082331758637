import React, { Component } from "react";

import Footer from "./Footer";
import $ from "jquery";
// import HeaderButtonBar from "./HeaderButtonBar";
import HeaderNavBar from './Navbar/HeaderNavBar'
import CookieConsentBanner from './CookieConsent';

class Jobs extends Component {

    constructor(props) {
        super(props);

        this.state = {

            jobData: {},

        };

    }


    componentDidMount() {
        this.loadJobData();

        console.log('componentDidMount called');
    }

    loadJobData() {
        var path = 'jobs/jobs_file.json'
        $.ajax({
            url: path,
            dataType: "json",
            cache: true,
            success: function (data) {
                this.setState({ jobData: data });

            }.bind(this),
            error: function (xhr, status, err) {
                alert(err);
            },
        });
    }



    render() {
       

        var govtName = this.state?.jobData?.jobs?.govt_jobs?.section_name
        var privateName = this.state?.jobData?.jobs?.private_jobs?.section_name
        var title = this.state?.jobData?.jobs?.title
        // var description = this.state?.jobData?.jobs?.description



        var govtData = this.state?.jobData?.jobs?.govt_jobs?.data.map(function (govtData, i) {
            return (
                <div>
                    {govtData?.header ? (
                        <div>

                            <p
                                className="text-left"
                                style={{ fontSize: "150%", marginTop: "4px" }}
                            >

                                <a href={govtData.description} target="_blank" rel="noopener noreferrer">

                                    <b> {govtData.header}</b>
                                </a>

                            </p>

                        </div>
                    ) : null}

                    <div>

                        {govtData?.description ? (
                            <div>

                                {/* <p
                                    className="text-left"
                                    style={{ fontSize: "100%", marginTop: "4px", textTransform: 'capitalize' }}
                                >

                                    <a href={"https://www.facebook.com/chaman.panchal/"} target="_blank" rel="noopener noreferrer">
                                        {govtData.description}
                                    </a>
                                </p> */}

                            </div>
                        ) : null}
                    </div>

                </div>


            );
        });


        var privateData = this.state?.jobData?.jobs?.private_jobs?.data.map(function (privateData, i) {
            return (
                <div>
                    {privateData?.header ? (
                        <div>

                            <p
                                className="text-left"
                                style={{ fontSize: "150%", marginTop: "4px" }}
                            >

                                <a href={privateData.description} target="_blank" rel="noopener noreferrer">

                                    <b> {privateData.header}</b>
                                </a>

                            </p>

                        </div>
                    ) : null}

                    <div>

                        {privateData?.description ? (
                            <div>

                                {/* <p
                                    className="text-left"
                                    style={{ fontSize: "100%", marginTop: "4px", textTransform: 'capitalize' }}
                                >

                                    <a href={"https://www.facebook.com/chaman.panchal/"} target="_blank" rel="noopener noreferrer">
                                        {govtData.description}
                                    </a>
                                </p> */}

                            </div>
                        ) : null}
                    </div>

                </div>


            );
        });



        return (
            <section id="AboutUs">
                <HeaderNavBar></HeaderNavBar>



                <div className="col-md-14">
                    <h1 className="col-md-14 mx-auto text-center" style={{ margin: "40px", color: "black" }}>
                        <span>{title}</span>
                    </h1>
                    <div className="row center mx-auto mb-5">
                        <div className="col-md-8 center">
                            <div className="col-md-10">
                                <div className="card" style={{background : "#F9F8EC"}} >
                                    <h1 className="col-md-14 mx-auto text-center" style={{ margin: "10px", color: "black" }}>
                                        <span>{govtName}</span>
                                    </h1>
                                    <div
                                        className="card-body font-trebuchet text-justify ml-3 mr-3"
                                        style={{
                                            height: "auto",
                                            fontSize: "132%",
                                            lineHeight: "200%",
                                        }}
                                    >
                                        <br />
                                        <span>{govtData}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row center mx-auto mb-5">
                        <div className="col-md-8 center">
                            <div className="col-md-10">
                                <div className="card" style={{background : "#F9F8EC"}}>
                                    <h1 className="col-md-14 mx-auto text-center" style={{ margin: "10px", color: "black" }}>
                                        <span>{privateName}</span>
                                    </h1>
                                    <div
                                        className="card-body font-trebuchet text-justify ml-3 mr-3"
                                        style={{
                                            height: "auto",
                                            fontSize: "132%",
                                            lineHeight: "200%",
                                        }}
                                    >
                                        <br />
                                        <span>{privateData}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
                <div>
                    <CookieConsentBanner />
                </div>
            </section>
        );
    }
}

export default Jobs;
