import React, { Component } from "react";
import $ from "jquery";

class Skills extends Component {

  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      sharedData: {},
      resumeData: {},
    };
  }


  componentDidMount() {
    this.loadSharedData();
    this.loadResumeFromPath();
    console.log('componentDidMount called');
  }

  loadResumeFromPath() {
    var path = 'res_primaryLanguage.json'
    $.ajax({
      url: path,
      dataType: "json",
      cache: true,
      success: function (data) {
        this.setState({ resumeData: data });
        console.log('loadResumeFromPath called');
      }.bind(this),
      error: function (xhr, status, err) {
        alert(err);
      },
    });
  }

  loadSharedData() {
    $.ajax({
      url: `portfolio_shared_data.json`,
      dataType: "json",
      cache: true,
      success: function (data) {
        this.setState({ sharedData: data });
        console.log('loadSharedData called');
        document.title = `${this.state.sharedData.basic_info.name}`;
        var title = this.state.sharedData.basic_info.name;
        console.log('title ' + title);
      }.bind(this),
      error: function (xhr, status, err) {
        alert(err);
      },

    });
  }
  render() {
   
      var sectionName = this.state.resumeData.basic_info?.section_name?.skills;
      var description = this.state.sharedData?.skills?.description;
      var skills = this.state.sharedData?.skills?.icons.map(function (skills, i) {
        return (
          <li className="list-inline-item mx-3" key={i}>
            <span>
              <div className="text-center skills-tile">
                <i className={skills.class} style={{ fontSize: "220%" }}>
                  <p
                    className="text-center"
                    style={{ fontSize: "30%", marginTop: "4px" }}
                  >
                    {skills.name}
                  </p>
                </i>
              </div>
            </span>
          </li>
        );
      });
    

    return (
      <section id="skills">
        <div className="col-md-12">
          <div className="col-md-12">
            <h1 className="section-title">
              <span className="text-white">{sectionName}</span>
            </h1>
            <h2 className="section-title">
              <span className="text-white">{description}</span>
            </h2>
          </div>
          <div className="col-md-12 text-center">
            <ul className="list-inline mx-auto skill-icon">{skills}</ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
