import React, { Component } from "react";

import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import CookieConsentBanner from './CookieConsent';

class Education extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 'Please write an essay about your favorite DOM element.'
        };
    }

    render() {
        return (
            <section id="about">
                <div style={{ margin: 0, color: '#333344', verticalAlign: "center" }} className="col-md-12 mx-auto text-right language">
                    <NavLink style={{ margin: 5, color: '#333344' }} exact activeClassName="active_class" to='/'>
                        Home
                    </NavLink>
                    <NavLink style={{ margin: 5, color: '#333344' }} exact activeClassName="active_class" to='/love'>
                        News
                    </NavLink>

                    <NavLink style={{ margin: 5, color: '#333344' }} exact activeClassName="active_class" to='/utility'>
                        Utility
                    </NavLink>
                    <NavLink style={{ margin: 5, color: '#333344' }} exact activeClassName="active_class" to='/pdm'>
                        Jobs
                    </NavLink>
                    <NavLink style={{ margin: 5, color: '#333344' }} exact activeClassName="active_class" to='/aboutUs'>
                        Contact
                    </NavLink>
                </div>



                <div className=" center" style={{ verticalAlign: "middle", textAlign: "center" }}>
                    <div className="header">
                        <h1 style={{ margin: 26, fontSize: 25 }}> Technical Tutorials </h1>
                        <div style={{ margin: 0, color: '#333344' }} className="col-md-12 mx-auto text-centret ">
                            <NavLink style={{ margin: 15, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/edu_android'>
                                Android
                            </NavLink>
                            <NavLink style={{ margin: 15, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/love'>
                                React
                            </NavLink>
                            <NavLink style={{ margin: 15, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/utility'>
                                Java
                            </NavLink>
                            <NavLink style={{ margin: 15, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/pdm'>
                                Spring
                            </NavLink>
                            <NavLink style={{ margin: 15, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/aboutUs'>
                                Servlets
                            </NavLink>
                            <NavLink style={{ margin: 15, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/aboutUs'>
                                Kotlin
                            </NavLink>


                        </div>


                        <h1 style={{ margin: 26, fontSize: 25 }}> Government Exam Prepration </h1>
                        <div style={{ margin: 0, color: '#333344' }} className="col-md-12 mx-auto text-centre">
                            <NavLink style={{ margin: 15, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/'>
                                JEE MAINS
                            </NavLink>
                            <NavLink style={{ margin: 15, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/love'>
                                CTET
                            </NavLink>
                            <NavLink style={{ margin: 15, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/utility'>
                                PMT
                            </NavLink>
                            <NavLink style={{ margin: 15, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/pdm'>
                                CIVIL EXAMS
                            </NavLink>
                            <NavLink style={{ margin: 15, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/aboutUs'>
                                SAMPLE PAPERS
                            </NavLink>
                        </div>





                    </div>
                </div>
                <Footer />
                <div>
                    <CookieConsentBanner />
                </div>

            </section>

        )
    }

}

export default Education;