// CookieConsent.js

import React, { Component } from 'react';
import CookieConsent from 'react-cookie-consent';
import { withCookies } from 'react-cookie';

class CookieConsentBanner extends Component {
  handleAccept = () => {
    const { cookies } = this.props;
    cookies.set('cookieConsent', true, { path: '/privacypolicy' });
  };

  render() {
    const { cookies } = this.props;
    const isConsentGiven = cookies.get('cookieConsent');

    if (isConsentGiven) {
      return null; // Do not render the banner if consent is already given
    }

    return (
      <CookieConsent
        location="bottom"
        buttonText="Got it!"
        cookieName="We use cookies to ensure you have the best browsing experience on our website. By using our site, you acknowledge that you have read and understood our"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '20px' }}
        onAccept={this.handleAccept}
      >
        <h3> We use cookies to ensure you have the best browsing experience on our website. By using our site, you acknowledge that you have read and understood our Policy </h3>
        <a href={"/privacypolicy"} data-action="/privacypolicy"
        > <h3 > Cookie Policy & Privacy Policy </h3> </a>



      </CookieConsent>
    );
  }
}

export default withCookies(CookieConsentBanner);
