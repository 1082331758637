import React, { Component } from "react";
import $ from "jquery";
import Footer from "./Footer";
// import HeaderButtonBar from "./HeaderButtonBar";
import HeaderNavBar from './Navbar/HeaderNavBar'
class Disclaimer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disclamerData: {},
        };
    }

    componentDidMount() {
        this.loadDisclamerFromPath();

    }

    loadDisclamerFromPath() {
        var path = 'disclaimer/disclaimer_file.json'
        // var path = 'privacypolicy.json'
        $.ajax({
            url: path,
            dataType: "json",
            cache: true,
            success: function (data) {
                this.setState({ disclamerData: data });
                console.log('loadResumeFromPath called');
            }.bind(this),
            error: function (xhr, status, err) {
                alert(err);
            },
        });
    }


    render() {


        var section_name = this.state.disclamerData?.disclamer?.disclamer_policy?.section_name

        var data = this.state.disclamerData?.disclamer?.disclamer_policy?.data.map(function (data, i) {
            return (
                <li key={i}>
                    <span>
                        <div >
                            <i className={data.class} style={{ fontSize: "220%", color: "black" }}>

                                {data?.header ? (
                                    <div>

                                        <p
                                            className="text-left"
                                            style={{ fontSize: "40%", marginTop: "4px" }}
                                        >
                                            <b> {data.header}</b>
                                        </p>

                                    </div>
                                ) : null}


                                {data?.description ? (
                                    <div>

                                        <p
                                            className="text-left"
                                            style={{ fontSize: "30%", marginTop: "4px", textTransform: 'capitalize' }}
                                        >
                                            {data.description}
                                        </p>

                                    </div>
                                ) : null}

                            </i>
                        </div>
                    </span>
                </li>
            );
        });


        return (

            <div style={{ color: "black", background: "#FCF9EA" }}>
                <HeaderNavBar></HeaderNavBar>
                <div style={{ color: "black", marginLeft: "20px", marginRight: "20px" }}>
                    <h1 className="section-title" style={{ color: "black" }}>
                        <h1 style={{ textTransform: 'capitalize', margin: "30px" }} ><b>{section_name}</b></h1>

                        {/* <h3 className="text-left" style={{ textTransform: 'capitalize', marginBottom: "10px" }}   > {description1}</h3> */}

                        <span style={{ color: "black" }} className="text-white">{data}</span>
                    </h1>

                </div>

                <Footer></Footer>
            </div>

        );
    }
}

export default Disclaimer;
