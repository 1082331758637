import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";

import Projects from "./Projects";
import Skills from "./Skills";
import Experience from "./Experience";
import $ from "jquery";
// import HeaderButtonBar from "./HeaderButtonBar";
import HeaderNavBar from './Navbar/HeaderNavBar'
// import AdsComponent from './AdsComponent';
// import CookieConsentBanner from './CookieConsentBanner';
import CookieConsentBanner from './CookieConsent';
// import Cookies from 'js-cookie';
// import CookieConsent from './CookieConsent';

class Home extends Component {

    //   cookie =  CookieConsent()

    constructor(props) {
        super(props);

        this.state = {
            foo: "bar",
            sharedData: {},
            resumeData: {},
            username: {},

            // cookies : {},
        };
    }


    componentDidMount() {
        this.loadSharedData();
        this.loadResumeFromPath();
        console.log('componentDidMount called');

    }

    loadResumeFromPath() {
        var path = 'res_primaryLanguage.json'
        $.ajax({
            url: path,
            dataType: "json",
            cache: true,
            success: function (data) {
                this.setState({ resumeData: data });
                console.log('loadResumeFromPath called');
            }.bind(this),
            error: function (xhr, status, err) {
                alert(err);
            },
        });
    }

    loadSharedData() {
        $.ajax({
            url: `portfolio_shared_data.json`,
            dataType: "json",
            cache: true,
            success: function (data) {
                this.setState({ sharedData: data });
                console.log('loadSharedData called');
                document.title = `${this.state.sharedData.basic_info.name}`;
                var title = this.state.sharedData.basic_info.name;
                console.log('title ' + title);
            }.bind(this),
            error: function (xhr, status, err) {
                alert(err);
            },

        });

    }

    render() {
        // var logopic = "images/logo.png"

        return (
            <section id="Home" style={{background : "#e9d5a1"}}>
                <HeaderNavBar></HeaderNavBar>
                <div className="col-md-12 mx-auto text-center " style={{background : "#e9d5a1"}}>

                    <h1 style={{marginTop : "20px"}}>Welcome To RetroSkills India </h1>
                    <h2 style={{marginBottom : "20px"}}>We Provide Free Technical Eduction To Poor Students </h2>
                </div>
                <Header sharedData={this.state.sharedData.basic_info} />

                <div className="col-md-12 mx-auto text-center language">
                    <div

                        style={{ display: "inline" }}
                    >
                        <span
                            className="iconify language-icon mr-5"
                            data-icon="twemoji-flag-for-flag-india"
                            data-inline="false"
                            id={window.$primaryLanguageIconId}
                        ></span>
                    </div>
                    <div

                        style={{ display: "inline" }}
                    >
                        <span
                            className="iconify language-icon mr-5 "
                            data-icon="twemoji-flag-for-flag-qatar"
                            data-inline="false"
                            id={window.$secondaryLanguageIconId}
                        ></span>

                    </div>
                    <div

                        style={{ display: "inline" }}
                    >
                        <span
                            className="iconify language-icon"
                            data-icon="twemoji-flag-for-flag-singapore"
                            data-inline="false"
                            id={window.$primaryLanguageIconId}
                        ></span>
                    </div>

                </div>

                <Projects></Projects>
                <Skills></Skills>
                {/* <div>
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8118826265420904"
                        crossorigin="anonymous"></script>
                    <AdsComponent dataAdSlot='8118826265420904' />
                </div> */}


                <Experience></Experience>



                <Footer />

                <div>
                    <CookieConsentBanner />
                </div>

            </section>
        )
    }
}
export default Home;