import React, { Component } from "react";
// import { useEffect } from 'react';

// import { NavLink } from "react-router-dom";
import HeaderButtonBar from "../HeaderButtonBar";
import $ from "jquery";
import Footer from "../Footer";
import { NavLink } from "react-router-dom";
class EduReactNative extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foo: "bar",
            value: 'Please write an essay about your favorite DOM element.',
            // section_name: '',
            data: '',
            androidData: {},

        };
    }


    componentDidMount() {
        this.loadAndroidData();
        console.log('componentDidMount called');
    }


    loadAndroidData() {
        $.ajax({
            url: `education/android_data.json`,
            //   url: `portfolio_shared_data.json`,
            dataType: "json",
            cache: true,
            success: function (data) {
                this.setState({ androidData: data });
                console.log('loadAndroidData called');
                console.log('androidData ' + data);
            }.bind(this),
            error: function (xhr, status, err) {
                alert("data " + err);
            },

        });

    }


    render() {
        let onClickFunction = (section_name) => {
            console.log('onClickFunction called' + section_name?.description);
            this.setState({ value: section_name });
        };

        var value = this.state?.value //destucture state

        var data = this.state.value?.data?.map(function (data, i) {
            return (
                <div className="col"   >
                    <i>
                        {data?.header ? (
                            <div>
                                <h1 style={{ textAlign: 'left', marginTop: 5, color: '#222277', fontWeight: 'bold' }} >
                                    {data?.header}
                                </h1>
                            </div>
                        ) : (

                            <div>
                                <h1 style={{ textAlign: 'left', marginTop: 5, color: '#222277', fontWeight: 'bold' }} >
                                    Please Select any Option
                                </h1>
                            </div>
                        )}



                        {data?.description ? (
                            <div>

                                <h2 style={{ textAlign: 'left', marginTop: 8, color: '#222222' }} >
                                    {data?.description}
                                </h2>

                            </div>
                        ) : null}

                        {/* <h1 style={{ textAlign: 'left', marginTop: 30, color: '#222277', fontWeight: 'bold' }} >
                            {data?.header}
                        </h1> */}
                        {/* <h2 style={{ textAlign: 'left', margin: 0, color: '#222222' }} >
                            {data?.description}
                        </h2> */}
                    </i>
                </div>
            )
        });

        var section_names = this.state.androidData?.android?.section_names?.map(function (section_names, i) {
            return (
                <div className="col" onClick={() => { onClickFunction(section_names) }}  >

                    <i>

                        <div style={{ textDecorationLine: 'underline',fontSize: 15, textAlign: 'left', margin: 10, color: '#229922', fontWeight: 'bold' }} >
                            <span className="wave">
                                {section_names?.section_name}

                            </span>

                        </div>
    
                    </i>
                </div>
            )
        });

        return (
            <section id="about">
                       <HeaderButtonBar></HeaderButtonBar>
              

                <div className="text-right" style = {{marginRight : "20px"}}>

                    <NavLink style={{ margin: 5, color: '#333399', fontSize: 20 }} exact activeClassName="active_class" to='/edu_android'>
                       <b>Android</b> 
                    </NavLink>
                    <NavLink style={{ margin: 5, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/edu_java'>
                        Java
                    </NavLink>
                    <NavLink style={{ margin: 5, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/edu_angular'>
                        Angular
                    </NavLink>
                    <NavLink style={{ margin: 5, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/edu_sqldb'>
                        SQL
                    </NavLink>
                    <NavLink style={{ margin: 5, color: '#333344', fontSize: 20 }} exact activeClassName="active_class" to='/edu_reactnative'>
                        React Native
                    </NavLink>
                </div >


                <div >
                    <div className="header"  >
                        <h1 style={{ margin: 26, fontSize: 25 }}> Android Tutorials </h1>

                        <div style={{ margin: 20, color: '#333344' }} className="row">
                            <div className="card" style={{ margin: 20, color: '#992222', height: '100%' }} >
                                <div  >
                                    {section_names}
                                    {/* <ul className="text-Left ">{section_names}</ul> */}

                                </div>
                            </div>
                            <div className="card" style={{
                                margin: 20, color: '#333344', width: '50%',
                                border: '1px solid black',
                                height: '100%'
                            }}>

                                <div
                                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                                    style={{
                                        height: "auto",
                                        fontSize: "132%",
                                        lineHeight: "200%",
                                    }}
                                >
                                    <br />
                                    <div style={{ textAlign: 'centre', margin: 0, color: '#333344', verticalAlign: "center" }} >
                                        <span style={{ textAlign: 'centre', margin: 10, color: '#552299', fontWeight: 'bold' }} className="wave"> {value?.section_name} </span>
                                    </div>

                                    {value?.header_one ? (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'centre', margin: 0, color: '#222222', fontWeight: 'bold' }} className="wave"> {value?.header_one} </span>
                                            <br />

                                        </div>
                                    ) : (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'centre', margin: 0, color: '#222222', fontWeight: 'bold' }} className="wave"> Android Tech Stack </span>
                                            <br />

                                        </div>
                                    )}



                                    {value?.description_one ? (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'centre', margin: 0, color: '#222222' }} className="wave"> {value?.description_one} </span>
                                            <br />

                                        </div>
                                    ) : null}

                                    <di>
                                        {/* Put Ads Here */}
                                    </di>


                                    {value?.header_two ? (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'centre', margin: 0, color: '#222222', fontWeight: 'bold' }} className="wave"> {value?.header_two} </span>
                                            <br />

                                        </div>
                                    ) : null}


                                    {value?.description_two ? (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'centre', margin: 0, color: '#222222' }} className="wave"> {value?.description_two} </span>
                                            <br />

                                        </div>
                                    ) : (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'centre', margin: 0, color: '#222222' }} className="wave"> Android is an open source, Linux-based software stack created for a wide array of devices and form factors</span>
                                            <br />

                                        </div>
                                    )}



                                    {data ? (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'centre', margin: 0, color: '#222222' }} className="wave"> {data} </span>
                                            <br />

                                        </div>
                                    ) : null}



                                </div>
                            </div>

                            {/* <div className="card" style={{ margin: 10, color: '#992222', height: '100%' }} >
                                <div >
                                   this is ads window 
                                    

                                </div>
                            </div> */}


                        </div>
                    </div>
                </div>




                <Footer />


            </section>

        )
    }

}

export default EduReactNative;