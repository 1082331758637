import React, { Component } from "react";
import Typical from "react-typical";
// import Switch from "react-switch";

// const puppeteer = require('puppeteer');


class Header extends Component {
  titles = [];

  constructor() {
    super();
    this.state = { checked: false };
    this.onThemeSwitchChange = this.onThemeSwitchChange.bind(this);
  }

  onThemeSwitchChange(checked) {
    this.setState({ checked });
    this.setTheme();
  }

  setTheme() {
    var dataThemeAttribute = "data-theme";
    var body = document.body;
    var newTheme =
      body.getAttribute(dataThemeAttribute) === "dark" ? "light" : "dark";
    body.setAttribute(dataThemeAttribute, newTheme);
  }

  render() {
    if (this.props.sharedData) {
      // var name = this.props.sharedData.name;
      // var business = this.props.sharedData.business;
      this.titles = this.props.sharedData.titles.map(x => [x.toUpperCase(), 3500]).flat();
    }

    const HeaderTitleTypeAnimation = React.memo(() => {
      return <Typical className="title-styles" steps={this.titles} loop={50} />
    }, (props, prevProp) => true);

    return (
      // <header id="home" >
        <div className=" text-center" style={{textAlign : "center"}}>
          <img width='250' height='250' src="../images/my_logo.png" alt="projectImages"></img>
          <br />
          <h1 className="mb-0">
            {/* <Typical steps={[business]} wrapper="p" /> */}
          </h1>
          <div className="title-container text-center" style={{textAlign : "center"}}>
            <HeaderTitleTypeAnimation />
          </div>
        
        </div>
      // </header>
    );
  }
}

export default Header;
