import React, { Component } from "react";
// import $ from "jquery";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ProjectDetailsModal from "./components/ProjectDetailsModal";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import AboutUs from "./components/AboutUs";

// import About from "./components/About";
import Home from "./components/Home";
// import LoveCalculator from "./components/LoveCalculator";
// import Experience from "./components/Experience";


// import { Route,Switch } from "react-router-dom";
import {  Route,Routes} from 'react-router-dom'
import WishPage from "./components/WishPage";
import Utility from "./components/Utility";
import Experience from "./components/Experience";
import HeaderButtonBar from "./components/HeaderButtonBar";
import Education from "./components/Education";
import AndroidPage from "./components/AndroidPage";
import News from "./components/News";
import WishFestivals from "./components/WishFestivals";
import TravelPoints from "./components/TravelPoints";
// import Movies from "./components/Movies";
import Weather from "./components/Weather";
import LatestNews from "./components/LatestNews";
import TrueCall from "./components/TrueCall";
import AdsComponent from "./components/AdsComponent";
import CookieConsent from "./components/CookieConsent";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Disclaimer from "./components/Disclaimer";
import Prepration from "./components/Prepration";
import CyberWarFare from "./components/interview/CyberWarFare";
import EduJava from "./components/education/EduJava";
import EduAngular from "./components/education/EduAngular";
import EduSqlDb from "./components/education/EduSqlDb";
import EduReactNative from "./components/education/EduReactNative";
import EducationSection from "./components/education/EducationSection";
import Jobs from "./components/Jobs";
import InterviewAndroid from "./components/interview/InterveiwAndroid";
import InterviewSection from "./components/interview/InterviewSection";
import InterviewReact from "./components/interview/InterviewReact";
import HeaderNavBar from "./components/Navbar/HeaderNavBar";
// import Switch from "react-bootstrap/esm/Switch";


class App extends Component {

  constructor(props) {
    super();
    this.state = {
      foo: "bar",
      resumeData: {},
      sharedData: {},
    };
  }

  render() {
    return (
      <>
        <Routes>
        
            {/* <Route exact path="/" Component={() => <About name="About" />} /> */}
            <Route path="/" element = {<Home/>} Component={Home}/>
            {/* <Route path="/love" element = {<LoveCalculator/>} Component={LoveCalculator} /> */}
            <Route path="/home" element = {<Home/>} Component={Home}/>
            <Route path="/header" element = {<Header/>} Component={Header} />
            <Route path="/ProjectDetailsModal" element = {<ProjectDetailsModal/>} Component={ProjectDetailsModal} />
            <Route path="/footer" element = {<Footer/>} Component={Footer} />
            <Route path="/projects" element = {<Projects/>} Component={Projects} />
            <Route path="/skills" element = {<Skills/>} Component={Skills} />
            <Route path="/wishpage/:fname" element = {<WishPage/>} Component={WishPage} />
            <Route path="/skills" element = {<Skills/>} Component={Skills} />
            <Route path="/utility" element = {<Utility/>} Component={Utility} />
            <Route path="/experience" element = {<Experience/>} Component={Experience} />
            <Route path="/hb" element = {<HeaderButtonBar/>} Component={HeaderButtonBar} />
            <Route path="/education" element = {<Education/>} Component={Education} />
            <Route path="/edu_android" element = {<AndroidPage/>} Component={AndroidPage} />
            <Route path="/news" element = {<News/>} Component={News} />
            <Route path="/wishfestivals" element = {<WishFestivals/>} Component={WishFestivals} />
            <Route path="/travel" element = {<TravelPoints/>} Component={TravelPoints} />
           
            {/* <Route path="/movies" element = {<Movies/>} Component={Movies} /> */}
            <Route path="/weather" element = {<Weather/>} Component={Weather} />
            <Route path="/latestnews" element = {<LatestNews/>} Component={LatestNews} />
            <Route path="/truecall" element = {<TrueCall/>} Component={TrueCall} />
            <Route path="/AdsComponent" element = {<AdsComponent/>} Component={AdsComponent} />
            <Route path="/CookieConsent" element = {<CookieConsent/>} Component={CookieConsent} />
            <Route path="/privacypolicy" element = {<PrivacyPolicy/>} Component={PrivacyPolicy} />
            <Route path="/disclaimer" element = {<Disclaimer/>} Component={Disclaimer} />
            <Route path="/prepration" element = {<Prepration/>} Component={Prepration} />
            <Route path="/cyberWarFare" element = {<CyberWarFare/>} Component={CyberWarFare} />
            <Route path="/edu_java" element = {<EduJava/>} Component={EduJava} />
            <Route path="/edu_angular" element = {<EduAngular/>} Component={EduAngular} />
            <Route path="/edu_sqldb" element = {<EduSqlDb/>} Component={EduSqlDb} />
            <Route path="/edu_reactnative" element = {<EduAngular/>} Component={EduReactNative} />
            <Route path="/education_section" element = {<EducationSection/>} Component={EducationSection} />
            {/* <Route path="/jobs" element = {<Jobs props = {{data: "hello"}}data={"hello"}/>} Component={Jobs} /> */}
            <Route path="/interview_android" element = {<InterviewAndroid/>} Component={InterviewAndroid} />
          
            <Route path="/interview_section" element = {<InterviewSection/>} Component={InterviewSection} />
            <Route path="/navbar" element = {<HeaderNavBar/>} Component={HeaderNavBar} />

            
            <Route path="/jobs" render={() => <Jobs myData={"Hello"} props = {"Hello"}/>} Component={Jobs} />
            {/* <Route path="/interview_ios" render={() => <InterviewIos data={"Hello"} />}  /> */}
            <Route path="/interview_react" element = {<InterviewReact/>} Component={InterviewReact} />
    
            <Route path="/aboutUs" element = {<AboutUs 
             resumeBasicInfo={this.state.resumeData.basic_info}
             aredBasicInfo={this.state.sharedData.basic_info}/>}
              Component={AboutUs} />
         
        </Routes>
      </>
    );
  }
}

export default App;

//https://www.youtube.com/watch?v=YUDB7oMMiUk