import { useState } from 'react'
import { NavLink } from 'react-router-dom'

// import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg'
// import { ReactComponent as Brand } from '../../assets/icons/logo.svg'
import './navbar.css'

const HeaderNavBar = () => {
    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    return (
        <nav className="navbar" style={{zIndex : '100'}}>
            <div className="container"  >
                <div className="logo">
                    <img style={{ alignItems: "left" }} height="100px" width='100px' src={`images/round_logo.png`} alt="logo" />
                </div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <img style={{}} src={`images/menu_icon.png`} alt="logo" />
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`} style={{ marginTop: "10px" }}>
                    <div className='text-left menu-icon-back ' style={{marginLeft: "40px" , margin : "20px"}} onClick={handleShowNavbar} >
            
                    <img style={{}} src={`images/right_arrow.png`} alt="logo" />
                    </div>
                        <ul>
                            <li>                          
                                <NavLink style={{ color: '#3333cc' }} exact activeClassName="active_class" to='/'>
                                    Home
                                </NavLink>
                                

                            </li>
                            <li>
                                <NavLink style={{ color: '#3333cc' }} exact activeClassName="active_class" to='/education_section'>
                                    Education
                                </NavLink>
                         

                            </li>
                            <li>
                                <NavLink style={{ color: '#3333cc' }} exact activeClassName="active_class" to='/prepration'>
                                    Prepration
                                </NavLink>
                             

                            </li>
                            <li>

                                <NavLink style={{ color: '#3333cc' }} exact activeClassName="active_class" to={{
                                    pathname: "/jobs"
                                }}>
                                    Jobs
                                </NavLink>

                          
                            </li>
                            <li>

                                <NavLink style={{ color: '#3333cc' }} exact activeClassName="active_class" to='/aboutUs'>
                                    Contact
                                </NavLink>
                             

                            </li>
                        </ul>
                    </div>
                {/* </div> */}


            </div>
        </nav>
    )
}

const Divider = () => {
    return (
        <hr
            style={{  backgroundColor: "#e9d5a1"}}
        ></hr>
    );
};

export default HeaderNavBar