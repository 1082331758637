import React, { Component } from "react";

import { NavLink } from "react-router-dom";

class HeaderButtonBar extends Component {
  render() {
    return (
      // <section id="header">

        <div style={{background : "#e9d5a1"}} className="row">
          {/* <div style={{ marginTop: "0px", color: '#ff3344' }} className="text-right "> */}
            <div style={{padding : "20px"  ,width : "10%"}} className="text-left" >
              <img style={{ marginLeft: "20px", textAlign: 'left' }} height="100px" width='100px' src={`images/round_logo.png`} alt="logo" />
            </div>
            <div  className="text-right" style={{paddingTop  :"45px", width: "85%", fontSize : "25px", textAlign: 'right' }} >
            <NavLink style={{ margin: 5, color: '#3333cc' }} exact activeClassName="active_class" to='/'>
              Home
            </NavLink>
            <NavLink style={{ margin: 5, color: '#3333cc' }} exact activeClassName="active_class" to='/education_section'>
              Education
            </NavLink>
            <NavLink style={{ margin: 5, color: '#3333cc' }} exact activeClassName="active_class" to='/prepration'>
              Prepration
            </NavLink>
            {/* <NavLink style={{ margin: 5, color: '#333344' }} exact activeClassName="active_class" to='/jobs'> */}
            <NavLink style={{ margin: 5, color: '#3333cc' }} exact activeClassName="active_class" to={{
              pathname: "/jobs"
            }}>
              Jobs
            </NavLink>
            <NavLink style={{ marginLeft :5, color: '#3333cc' }} exact activeClassName="active_class" to='/aboutUs'>
              Contact
            </NavLink>
            </div>
          {/* </div> */}

        </div>

      // </section>

    )
  }

}

export default HeaderButtonBar;