import React from "react";
// import { Button } from "react-bootstrap";
// import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
// import HeaderButtonBar from "./HeaderButtonBar";
import HeaderNavBar from './Navbar/HeaderNavBar'
import Footer from "./Footer";

const Utility = (props) => {

    return (

        <div>
            <div>

                <HeaderNavBar></HeaderNavBar>
            </div>

            <div className="col-md-12 mx-auto text-right language">



                <NavLink style={{ margin: 5, color: '#333344' }} exact activeClassName="active_class" to='/wishpage/well_wisher'>
                    Wish Festivales
                </NavLink>
            </div>
            <Footer />

        </div>
    )
}

export default Utility;