
import axios from 'axios';
import { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import HeaderButtonBar from "./HeaderButtonBar";
import HeaderNavBar from './Navbar/HeaderNavBar'
import Footer from "./Footer";


function LatestNews() {

    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(false);


    //Make api call to news api
    async function getNewsData() {
        //Set loading boolean to true so that we know to show loading text
        setLoading(true);

        //Make news api call using axios
        const resp = await axios.get("https://newsapi.org/v2/everything?q=bitcoin&apiKey=d1869df132884e589bdda203cbf5fc20&pageSize=50");
        setNewsData(resp.data.articles);

        //Set loading boolean to false so that we know to show news articles
        setLoading(false);
    }

    useEffect(() => {
        getNewsData();
    }, []);



    return (
        <>
            <div className="background_container">
                <HeaderNavBar></HeaderNavBar>
                {/* <div>
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8118826265420904"
                        crossorigin="anonymous"></script>
                </div> */}
                <div className="row">
                    <div className="card" style={{
                        color: '#330000', width: '33%',
                        border: '1px solid skyblue',
                        height: '100%', background: '#c5e3e2'
                    }}>

                    </div>

                    <div className="card" style={{
                        color: '#333344', width: '30%',
                        background: '#c5e3e2',
                        height: '100%'
                    }}>
                        {loading ? "Loading..." : <Container>
                            {newsData.map((newsData, index) =>
                                <Row className="d-flex justify-content-center">
                                    <Col xs={20} className="mt-4 w-100 justify-content-center" key={index}>
                                        <a href={newsData.url}>
                                            <Card style={{
                                                color: '#333344', width: '100%',
                                                border: '2px solid skyblue',
                                                height: '100%'
                                            }} >
                                                <Card.Title className="my-3">  {newsData.title}</Card.Title>
                                                <Card.Img src={newsData.urlToImage} />
                                                <Card.Body>

                                                    <Card.Text>
                                                        {newsData.description}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </a>
                                    </Col>
                                    {/* </div> */}

                                </Row>
                            )}

                        </Container>

                        }
                    </div>
                    {/* </header> */}


                    <div className="card" style={{
                        color: '#333344', width: '33%',
                        border: '1px solid skyblue',
                        height: '100%', background: '#c5e3e2'
                    }}>

                    </div>
                </div>
            </div>

            <Footer />

        </>
    );
}

export default LatestNews;