import React from "react";
// import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
// import Blink from 'react-blink-text';
import Footer from "./Footer";

const WishPage = () => {
    const { fname } = useParams()


    const [name, setName] = React.useState("");

    var domain =  'https://www.retroskills.com/wishpage/';
    // var domain = 'http://localhost:3000/wishpage/';
    // var path = name ? "/wishpage/" + name : "/wishpage/Chaman Panchal"
    var wishPath = name ? "whatsapp://send?text=" + domain + name : "whatsapp://send?text=" + domain + "Well_Wisher"
    var webWishPath = name ? domain + name : domain + "Well_Wisher"

    const changeHandler = (e) => {
        const { value } = e.target;
        if (value !== "") {
            setName(value);
        }
    };

    const image = "../wishimage/wish.jpg";
    return (
        <section id="about">
            {/* <header id="home" style={{ height: window.innerHeight,display:'block' }}> */}
            <header id="home" style={{ height: '0%', display: 'block' }}>
                <div className="row aligner" style={{ height: '1000%' }}>
                    {/* <div className="row aligner" style={{ height: '100%' , background: 'rgba(0, 0, 0,0.3)', backgroundImage : 'url(https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350)'}}> */}
                    <div className="col-md-5">
                        <h1> Wish You Happy Diwali </h1>
                        <div>
                            <div >
                                {/* <span className="iconify header-icon" data-icon="la:laptop-code" data-inline="false"></span> */}
                                <span style={{ cursor: "auto" }}>
                                    <img
                                        height="250px"
                                        src={image}
                                        alt="new"
                                    /></span>
                            </div>


                            <div style={{ fontSize: 20, marginBottom: 10, marginTop: 10 }}>
                                <span >Regards :   {<span style={{ color: 'red', font: 'bold' }}> <b> {fname} </b> </span>} </span>
                            </div>


                            <div style={{ fontSize: 20, alignItems: 'centre', margin: 0, color: '#333344', verticalAlign: "center" }}  >
                                <div >
                                    <input
                                        type="text"
                                        enterKeyHint="Your Name Please "
                                        onChange={changeHandler}
                                    />
                                </div>

                                <div className="title-container">

                                </div>
                                <div>
                                    <a href={wishPath} data-action="share/whatsapp/share"
                                    > <h3 > Whatsapp Share Wish With Your Name </h3> </a>

                                    <a href={webWishPath} data-action="share/chrome/share"
                                    > <h3 > Web Share Wish With Your Name </h3> </a>
                                </div>
                            </div>
                            <div className="title-container">

                            </div>
                            <div className="title-container">

                            </div>



                        </div>
                    </div>
                </div>
            </header>

            <Footer />
        </section>


    )

}
export default WishPage
