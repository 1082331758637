import React, { Component } from "react";
import $ from "jquery";

import { NavLink } from "react-router-dom";
class Footer extends Component {


  constructor(props) {
    super(props);
    this.state = {
      sharedData: {},
    };
  }

  componentDidMount() {
    this.loadSharedData();

  }

  loadSharedData() {
    $.ajax({
      url: `portfolio_shared_data.json`,
      dataType: "json",
      cache: true,
      success: function (data) {
        this.setState({ sharedData: data });
      }.bind(this),
      error: function (xhr, status, err) {
        alert(err);
      },

    });

  }
  render() {
    if (this.state?.sharedData?.basic_info) {
      var networks = this.state?.sharedData?.basic_info?.social?.map(function (network) {
        return (
          <span key={network.name} className="m-4">
            <a href={network.url} target="_blank" rel="noopener noreferrer">
              <i className={network.class}></i>
            </a>
          </span>
        );
      });
    }

    return (
      <footer>
        <div className="col-md-14 text-center">

          <div className="social-links">{networks}</div>
          <div className="text-right" style={{ color: '#333344', marginRight : "20px" }} >

            <NavLink style={{ margin: 5, color: '#ffffff' }} exact activeClassName="active_class" to='/privacypolicy'>
              PrivacyPolicy
            </NavLink>
            <NavLink style={{ margin: 5, color: '#ffffff' }} exact activeClassName="active_class" to='/disclaimer'>
              Disclamer
            </NavLink>

          </div>
          <div className="copyright py-4 text-center">
            <div className="text-center">
              <small>
                {/* Copyright &copy;{" "}
                {this.props.sharedBasicInfo
                  ? this.props.sharedBasicInfo.name
                  : "???"} */}
                Copyright &copy;{" "} Retro Skills India
              </small>
            </div>
          </div>



        </div>
      </footer>
    );
  }
}

export default Footer;
