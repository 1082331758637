import React, { Component } from "react";
import HeaderButtonBar from "../HeaderButtonBar";
import $ from "jquery";
import Footer from "../Footer";
// import { NavLink } from "react-router-dom";
class InterviewAndroid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foo: "bar",
            value: 'No Data Found.',
            section_name: '',
            data: '',
            interviewData: {},

        };
    }


    componentDidMount() {
        this.loadCyberData();
        console.log('componentDidMount called');
    }


    loadCyberData() {
        $.ajax({
            url: `prepration/android_interview_data.json`,
            //   url: `portfolio_shared_data.json`,
            dataType: "json",
            cache: true,
            success: function (data) {
                this.setState({ interviewData: data });

            }.bind(this),
            error: function (xhr, status, err) {
                alert("data " + err);
            },

        });

    }


    render() {
        let onClickFunction = (section_name) => {
            console.log('onClickFunction called' + section_name?.description);
            this.setState({ value: section_name });
        };

        var value = this.state?.value //destucture state
        var title = this.state?.interviewData?.interview_data?.title
        var defaultHeader = this.state?.interviewData?.interview_data?.default_header
        var defaultDescription1 = this.state?.interviewData?.interview_data?.default_description1
        // var defaultDescription2 = this.state?.interviewData?.interview_data?.default_description2
        var description = this.state?.interviewData?.interview_data?.description
        var index = 0
        var data = this.state.value?.data?.map(function (data, i) {
            if (data?.header !== "") index = index + 1
            return (
                <div className="col"   >

                    {data?.header ? (
                        <div style={{ textTransform: 'capitalize' }}>
                            <h2 style={{ textTransform: 'capitalize', textAlign: 'left', marginTop: 5, color: '#222277', fontWeight: 'bold' }} >
                                {index} ). {data?.header}
                            </h2>
                        </div>
                    ) : (

                        null
                    )}



                    {data?.description ? (
                        <div>

                            <h3 style={{ textAlign: 'left', marginTop: "8px", marginBottom: "8px", color: '#222222' }} >
                                {data?.description}
                            </h3>

                        </div>
                    ) : null}

                </div>
            )
        });

        var section_names = this.state.interviewData?.interview_data?.section_names?.map(function (section_names, i) {
            return (
                // <li key={i}>
                <div className="col" onClick={() => { onClickFunction(section_names) }}  >

                    <i>

                        <div style={{ textDecorationLine: 'underline', fontSize: 12, textAlign: 'left', margin: 10, color: '#229922', fontWeight: 'bold' }} >
                            <span className="wave">
                                {i + 1}.{section_names?.section_name}

                            </span>

                        </div>
                        {/* <h1 style={{ textAlign: 'left', margin: 10, color: '#229922', fontWeight: 'bold' }} >
                            {section_names?.section_name}
                        </h1> */}
                    </i>
                </div>
                // </li>
            )
        });

        return (
            <section id="about">
                <div style={{ margin: 0, color: '#333344', verticalAlign: "center" }} className="col-md-12 mx-auto text-Left language">
                    <HeaderButtonBar></HeaderButtonBar>

                </div>


                <div >
                    <div className="header"  >
                        <h1 className="text-center" style={{ margin: 26, fontSize: 25 }}> {title} </h1>
                        <h2 className="text-center" style={{ margin: 26, fontSize: 25 }}> {description} </h2>

                        <div style={{ margin: 10, color: '#333344' }} className="row">

                            <div className="card" width="40%" style={{ background: "#FAF8EB", margin: 20, color: '#992222', height: '100%' }} >

                                <div style={{ fontWeight: 'bold', fontSize: "250%", textAlign: 'center', margin: '20px', color: '#992222', height: '50%' }} >

                                    Latest Topics
                                </div>
                                <div  >

                                    {section_names}
                                    {/* <ul className="text-Left ">{section_names}</ul> */}

                                </div>
                            </div>
                            <div className="card" style={{
                                background: "#FAF8EB",
                                margin: "20px", color: '#333344', width: '65%',
                                border: '1px solid black',
                                height: '100%'
                            }}>

                                <div
                                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                                    style={{
                                        height: "auto",
                                        fontSize: "132%",
                                        lineHeight: "200%",
                                    }}
                                >
                                    <br />


                                    {/* value?.section_name */}
                                    <div style={{ textAlign: 'center', margin: 0, color: '#333344', verticalAlign: "center" }} >
                                        <span style={{ textAlign: 'center', margin: 10, color: '#552299', fontWeight: 'bold' }} className="wave"> {value?.section_name} </span>
                                    </div>

                                    {value?.section_name ? (
                                        null
                                    ) : (
                                        <div>
                                            <div>
                                                <br />
                                                <span style={{ textAlign: 'center', margin: 0, color: '#222222', fontWeight: 'bold' }} className="wave"> {defaultHeader}</span>
                                                <br />
                                            </div>

                                            <div>
                                                <br />
                                                <span style={{ textAlign: 'centre', margin: 0, color: '#222222' }} className="wave"> {defaultDescription1}</span>
                                                <br />

                                            </div>
                                        </div>
                                    )}

                                    {value?.header_one ? (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'center', margin: 0, color: '#222222', fontWeight: 'bold' }} className="wave"> {value?.header_one} </span>
                                            <br />

                                        </div>
                                    ) : (
                                        // <div>
                                        //     <br />
                                        //     <span style={{ textAlign: 'center', margin: 0, color: '#222222', fontWeight: 'bold' }} className="wave"> {defaultHeader}</span>
                                        //     <br />

                                        // </div>
                                        null
                                    )}



                                    {value?.description_one ? (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'center', margin: 0, color: '#222222' }} className="wave"> {value?.description_one} </span>
                                            <br />

                                        </div>
                                    ) :
                                        (

                                            // <div>
                                            //     <br />
                                            //     <span style={{ textAlign: 'centre', margin: 0, color: '#222222' }} className="wave"> {defaultDescription1}</span>
                                            //     <br />

                                            // </div>
                                            null

                                        )}

                                    <di>
                                        {/* Put Ads Here */}
                                    </di>


                                    {value?.header_two ? (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'centre', margin: 0, color: '#222222', fontWeight: 'bold' }} className="wave"> {value?.header_two} </span>
                                            <br />

                                        </div>
                                    ) :
                                        null
                                    }


                                    {value?.description_two ? (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'centre', margin: 0, color: '#222222' }} className="wave"> {value?.description_two} </span>
                                            <br />

                                        </div>
                                    ) : (
                                        null
                                    )}



                                    {data ? (
                                        <div>
                                            <br />
                                            <span style={{ textAlign: 'centre', margin: 0, color: '#222222' }} className="wave"> {data} </span>
                                            <br />

                                        </div>
                                    ) : null}



                                </div>
                            </div>

                            {/* <div className="card" style={{ margin: 10, color: '#992222', height: '100%' }} >
                                <div >
                                   this is ads window 
                                    

                                </div>
                            </div> */}


                        </div>
                    </div>
                </div>




                <Footer />


            </section>

        )
    }

}

export default InterviewAndroid;