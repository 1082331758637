import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";

import Projects from "./Projects";
import Skills from "./Skills";
import Experience from "./Experience";
import $ from "jquery";
// import HeaderButtonBar from "./HeaderButtonBar";


class TrueCall extends Component {

    constructor(props) {
        super(props);
        this.state = {
            foo: "bar",
            sharedData: {},
            resumeData: {},
        };
    }


    componentDidMount() {
        this.loadSharedData();
        this.loadResumeFromPath();
        console.log('componentDidMount called');
    }

    loadResumeFromPath() {
        // var path = '../truecall/true_call_data.json'
        var path = 'truecall/true_call_res_data.json'
        $.ajax({
            url: path,
            dataType: "json",
            cache: true,
            success: function (data) {
                this.setState({ resumeData: data });
                console.log('loadResumeFromPath called');
            }.bind(this),
            error: function (xhr, status, err) {
                alert(err);
            },
        });
    }

    loadSharedData() {
        $.ajax({
            // url: `portfolio_shared_data.json`,
            url: `truecall/true_call_shared_data.json`,
            dataType: "json",
            cache: true,
            success: function (data) {
                this.setState({ sharedData: data });
                console.log('loadSharedData called');
                document.title = `${this.state.sharedData.basic_info.name}`;
                var title = this.state.sharedData.basic_info.name;
                console.log('title ' + title);
            }.bind(this),
            error: function (xhr, status, err) {
                alert(err);
            },

        });

    }

    render() {
        // var logopic = "images/logo.png"
        return (
            <section id="Home">
       
                <div className="col-md-12 mx-auto text-center language">

                    <h1>Welcome To True Call Girls</h1>
                </div>


                <Header sharedData={this.state.sharedData.basic_info} />

                <div className="col-md-12 mx-auto text-center language">
                    <div

                        style={{ display: "inline" }}
                    >
                        <span
                            className="iconify language-icon mr-5"
                            data-icon="twemoji-flag-for-flag-india"
                            data-inline="false"
                            id={window.$primaryLanguageIconId}
                        ></span>
                    </div>
                    <div

                        style={{ display: "inline" }}
                    >
                        <span
                            className="iconify language-icon mr-5 "
                            data-icon="twemoji-flag-for-flag-united-kingdom"
                            data-inline="false"
                            id={window.$secondaryLanguageIconId}
                        ></span>

                    </div>
                    <div

                        style={{ display: "inline" }}
                    >
                        <span
                            className="iconify language-icon"
                            data-icon="twemoji-flag-for-flag-poland"
                            data-inline="false"
                            id={window.$primaryLanguageIconId}
                        ></span>
                    </div>

                </div>

                <Projects></Projects>
                <Skills></Skills>
                <Experience></Experience>
                <Footer sharedBasicInfo={this.state.sharedData.basic_info} />

            </section>
        )
    }
}
export default TrueCall;